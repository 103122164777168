<template>
  <div class="AdminSubscriptionsReportFilters">
    <LargeButton icon="stars" :label="$locale['susbcriptions_by_creator']" @click="goFilter({ feed: 'admin-susbcriptions-by-creator' })" />
  </div>
</template>

<script>
export default {
  props: ["modal"],
  methods: {
    goFilter: function(data) {
      this.modal.close(() => {
        this.setQuery(data);
      });
    },
  },
};
</script>
